@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overscroll-behavior-x: none;
}

@layer base {
  :root {
    /* theme-blue.dark */
    --editor: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
    --editor-background: 220 20% 15%; /* hsl(220,20%, 15%) */
    --editor-text: 223 0.58% 92.4%; /* hsl(223,0.58%, 92.4%) */
    --editor-button: 240 10% 3.9%; /* hsl(223,19%, 14%) */
    --editor-button-active: 200 25% 30%; /* hsl(200,25%, 30%) */
    --editor-border: 240 3.7% 15.9%; /* hsl(240,3.7%, 15.9%) */

    --text-xxs: 0.7rem;
    --text-xs: 0.75rem;
    --text-sm: 0.875rem;
    --text-base: 1rem;
    --text-lg: 1.125rem;
    --text-xl: 1.25rem;
    --text-2xl: 1.5rem;
    --text-3xl: 1.875rem;
    --text-4xl: 2.25rem;
    --text-5xl: 3rem;
    --text-6xl: 3.75rem;
    --text-7xl: 4.768rem;
    --text-8xl: 5.96rem;
  }

  .font-size-sm {
    --text-xxs: 0.75rem;
    --text-xs: 0.875rem;
    --text-sm: 1rem;
    --text-base: 1.125rem;
    --text-lg: 1.25rem;
    --text-xl: 1.5rem;
    --text-2xl: 1.875rem;
    --text-3xl: 2.25rem;
    --text-4xl: 3rem;
    --text-5xl: 3.75rem;
    --text-6xl: 4.768rem;
    --text-7xl: 5.96rem;
    --text-8xl: 7.5rem;
  }
  .theme-multicolor {
    --background: 223 0.58% 92.4%; /* hsl(223,0%, 92%) */
    --foreground: 240 10% 3.9%; /* hsl(240,10%, 4%) */
    --header: 223 96.45% 98.48%; /* hsl(223,96%,98%) */
    --gradient-from: 12 6.5% 15%; /*#292524  stone-800*/
    --gradient-to: 240 5.3% 26%; /*#3f3f46  zinc-700*/
    --gradient-text: 0 0% 25%; /* hsl(0,0%, 25%) */ /*hsl(240,10%,3.9%)*/
    --gradient-text: 0 0% 98%;
    --card: 0, 0%, 98%; /* hsl(0, 0%, 98%) */
    --card-foreground: 240 10% 3.9%; /* hsl(240,10%, 4%) */
    --popover: 0 0% 100%; /* hsl(0,0%, 100%) */
    --popover-foreground: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
    --primary: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */ /*#18181b*/
    --primary-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --secondary: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --secondary-foreground: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --muted: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --muted-foreground: 240 3.8% 46.1%; /* hsl(240,3.8%, 46.1%) */
    --accent: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --accent-foreground: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --destructive: 0 84.2% 60.2%; /* hsl(0,84.2%, 60.2%) */
    --destructive-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --border: 240 5.9% 90%; /* hsl(240,5.9%, 90%) */
    --input: 240 5.9% 90%; /* hsl(240,5.9%, 90%) */
    --ring: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --text: 0 0% 25%; /* hsl(0,0%, 25%) */ /*hsl(240,10%,3.9%)*/
  }
  .theme-wb {
    --background: 223 0.58% 92.4%; /* hsl(223,0%, 92%) */
    --foreground: 240 10% 3.9%; /* hsl(240,10%, 4%) */
    --header: 223 96.45% 98.48%; /* hsl(223,96%,98%) */
    --gradient-from: 12 6.5% 15%; /*#292524  stone-800*/
    --gradient-to: 240 5.3% 26%; /*#3f3f46  zinc-700*/
    --gradient-text: 0 0% 25%; /* hsl(0,0%, 25%) */ /*hsl(240,10%,3.9%)*/
    --gradient-text: 0 0% 98%;
    --card: 0, 0%, 98%; /* hsl(0, 0%, 98%) */
    --card-foreground: 240 10% 3.9%; /* hsl(240,10%, 4%) */
    --popover: 0 0% 100%; /* hsl(0,0%, 100%) */
    --popover-foreground: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
    --primary: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */ /*#18181b*/
    --primary-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --secondary: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --secondary-foreground: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --muted: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --muted-foreground: 240 3.8% 46.1%; /* hsl(240,3.8%, 46.1%) */
    --accent: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --accent-foreground: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --destructive: 0 84.2% 60.2%; /* hsl(0,84.2%, 60.2%) */
    --destructive-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --border: 240 5.9% 90%; /* hsl(240,5.9%, 90%) */
    --input: 240 5.9% 90%; /* hsl(240,5.9%, 90%) */
    --ring: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --text: 0 0% 25%; /* hsl(0,0%, 25%) */ /*hsl(240,10%,3.9%)*/
  }
  .theme-wb.light {
    --background: 223 0.58% 92.4%; /* hsl(223,0%, 92%) */
    --foreground: 240 10% 3.9%; /* hsl(240,10%, 4%) */
    --header: 223 96.45% 98.48%; /* hsl(223,96%,98%) */
    --gradient-from: 240 4.76% 95.88%; /* #f4f4f5  zinc-100 */
    --gradient-to: 220, 13.04%, 90.98%; /* #e5e7eb  gray-200 */
    --gradient-text: 240 10% 3.9%;
    --card: 0, 0%, 98%; /* hsl(0, 0%, 98%) */
    --card-foreground: 240 10% 3.9%; /* hsl(240,10%, 4%) */
    --popover: 0 0% 100%; /* hsl(0,0%, 100%) */
    --popover-foreground: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
    --primary: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */ /*#18181b*/
    --primary-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --secondary: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --secondary-foreground: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --muted: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --muted-foreground: 240 3.8% 46.1%; /* hsl(240,3.8%, 46.1%) */
    --accent: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --accent-foreground: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --destructive: 0 84.2% 60.2%; /* hsl(0,84.2%, 60.2%) */
    --destructive-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --border: 240 5.9% 90%; /* hsl(240,5.9%, 90%) */
    --input: 240 5.9% 90%; /* hsl(240,5.9%, 90%) */
    --ring: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --text: 0 0% 25%; /* hsl(0,0%, 25%) */
  }

  .theme-wb.dark {
    --background: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
    --foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --header: 240 3.7% 15.9%; /* hsl(222,84%, 5%) */
    --gradient-from: 12 6.5% 15%; /*#292524  stone-800*/
    --gradient-to: 240 5.3% 26%; /*#3f3f46  zinc-700*/
    --gradient-text: 0 0% 98%; /* hsl(0,0%, 98%) */
    --text: 223 96.45% 98.48%; /* hsl(223,96.45%, 98.48%) */
    --card: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
    --card-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --popover: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
    --popover-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --primary: 0 0% 98%; /* hsl(0,0%, 98%) */
    --primary-foreground: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --secondary: 240 3.7% 15.9%; /* hsl(240,3.7%, 15.9%) */
    --secondary-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --muted: 240 3.7% 15.9%; /* hsl(240,4%, 16%) */
    --muted-foreground: 240 5% 64.9%; /* hsl(240,5%, 64.9%) */
    --accent: 240 3.7% 15.9%; /* hsl(240,3.7%, 15.9%) */
    --accent-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --destructive: 0 62.8% 30.6%; /* hsl(0,62.8%, 30.6%) */
    --destructive-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --border: 240 3.7% 15.9%; /* hsl(240,3.7%, 15.9%) */
    --input: 240 3.7% 15.9%; /* hsl(240,3.7%, 15.9%) */
    --ring: 240 4.9% 83.9%; /* hsl(240,4.9%, 83.9%) */
    --text: 223 96.45% 98.48%; /* hsl(223,96.45%, 98.48%) */
  }
  .theme-green {
    --background: 0 0% 100%; /* hsl(0,0%, 100%) */
    --foreground: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
    --header: 223 96.45% 98.48%; /* hsl(223,96%,98%) */
    --gradient-from: 143 61% 20%; /*#14532d  green-900*/
    --gradient-to: 163.15 93.5% 24.8%; /*##047857  emerald-700*/
    --gradient-text: 0 0% 98%;
    --card: 0 0% 100%; /* hsl(0,0%, 100%) */
    --card-foreground: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
    --popover: 0 0% 100%; /* hsl(0,0%, 100%) */
    --popover-foreground: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
    --primary: 142.1 76.2% 36.3%; /* hsl(142.1,76.2%, 36.3%) */
    --primary-foreground: 355.7 100% 97.3%; /* hsl(355.7,100%, 97.3%) */
    --secondary: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --secondary-foreground: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --muted: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --muted-foreground: 240 3.8% 46.1%; /* hsl(240,3.8%, 46.1%) */
    --accent: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --accent-foreground: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --destructive: 0 84.2% 60.2%; /* hsl(0,84.2%, 60.2%) */
    --destructive-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --border: 240 5.9% 90%; /* hsl(240,5.9%, 90%) */
    --input: 240 5.9% 90%; /* hsl(240,5.9%, 90%) */
    --ring: 142.1 76.2% 36.3%; /* hsl(142.1,76.2%, 36.3%) */
    --text: 0 0% 25%; /* hsl(0,0%, 25%) */
  }
  .theme-green.dark {
    --background: 20 14.3% 4.1%; /* hsl(20,14.3%, 4.1%) */
    --foreground: 0 0% 95%; /* hsl(0,0%, 95%) */
    --header: 222.2 84% 4.9%; /* hsl(222,84%, 5%) */
    --gradient-from: 144.9 80.4% 10%; /*#052e16  green-950*/
    --gradient-to: 165.71 91.3% 9.5%; /*#022c22  emerald-950*/
    --gradient-text: 0 0% 98%;
    --card: 24 9.8% 10%; /* hsl(24,9.8%, 10%) */
    --card-foreground: 0 0% 95%; /* hsl(0,0%, 95%) */
    --popover: 0 0% 9%; /* hsl(0,0%, 9%) */
    --popover-foreground: 0 0% 95%; /* hsl(0,0%, 95%) */
    --primary: 142.1 70.6% 45.3%; /* hsl(142.1,70.6%, 45.3%) */
    --primary-foreground: 144.9 80.4% 10%; /* hsl(144.9,80.4%, 10%) */
    --secondary: 240 3.7% 15.9%; /* hsl(240,3.7%, 15.9%) */
    --secondary-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --muted: 0 0% 15%; /* hsl(0,0%, 15%) */
    --muted-foreground: 240 5% 64.9%; /* hsl(240,5%, 64.9%) */
    --accent: 12 6.5% 15.1%; /* hsl(12,6.5%, 15.1%) */
    --accent-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --destructive: 0 62.8% 30.6%; /* hsl(0,62.8%, 30.6%) */
    --destructive-foreground: 0 85.7% 97.3%; /* hsl(0,85.7%, 97.3%) */
    --border: 240 3.7% 15.9%; /* hsl(240,3.7%, 15.9%) */
    --input: 240 3.7% 15.9%; /* hsl(240,3.7%, 15.9%) */
    --ring: 142.4 71.8% 29.2%; /* hsl(142.4,71.8%, 29.2%) */
    --text: 223 96.45% 98.48%; /* hsl(223,96.45%, 98.48%) */
  }

  .theme-red {
    --background: 0 0% 100%; /* hsl(0,0%, 100%) */
    --foreground: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
    --header: 223 96.45% 98.48%; /* hsl(223,96%,98%) */
    --gradient-from: 0 62.8% 30.7%; /*#7f1d1d  red-900*/
    --gradient-to: 0 70% 35.3%; /*#991b1b  red-800*/
    --gradient-text: 0 0% 98%;
    --card: 0 0% 100%; /* hsl(0,0%, 100%) */
    --card-foreground: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
    --popover: 0 0% 100%; /* hsl(0,0%, 100%) */
    --popover-foreground: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
    --primary: 346.8 77.2% 49.8%; /* hsl(346.8,77.2%, 49.8%) */
    --primary-foreground: 355.7 100% 97.3%; /* hsl(355.7,100%, 97.3%) */
    --secondary: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --secondary-foreground: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --muted: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --muted-foreground: 240 3.8% 46.1%; /* hsl(240,3.8%, 46.1%) */
    --accent: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
    --accent-foreground: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
    --destructive: 0 84.2% 60.2%; /* hsl(0,84.2%, 60.2%) */
    --destructive-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --border: 240 5.9% 90%; /* hsl(240,5.9%, 90%) */
    --input: 240 5.9% 90%; /* hsl(240,5.9%, 90%) */
    --ring: 346.8 77.2% 49.8%; /* hsl(346.8,77.2%, 49.8%) */
    --text: 0 0% 25%; /* hsl(0,0%, 25%) */
  }

  .theme-red.dark {
    --background: 20 14.3% 4.1%; /* hsl(20,14.3%, 4.1%) */
    --foreground: 0 0% 95%; /* hsl(0,0%, 95%) */
    --header: 0 74.7% 15.5%; /* hsl(222,84%, 5%) */
    --gradient-from: 0 74.7% 15.5%; /*#450a0a  red-950*/
    --gradient-to: 0 62.8% 30.7%; /*#7f1d1d  red-900*/
    --gradient-text: 0 0% 98%;
    --card: 24 9.8% 10%; /* hsl(24,9.8%, 10%) */
    --card-foreground: 0 0% 95%; /* hsl(0,0%, 95%) */
    --popover: 0 0% 9%; /* hsl(0,0%, 9%) */
    --popover-foreground: 0 0% 95%; /* hsl(0,0%, 95%) */
    --primary: 346.8 77.2% 49.8%; /* hsl(346.8,77.2%, 49.8%) */
    --primary-foreground: 355.7 100% 97.3%; /* hsl(355.7,100%, 97.3%) */
    --secondary: 240 3.7% 15.9%; /* hsl(240,3.7%, 15.9%) */
    --secondary-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --muted: 0 0% 15%; /* hsl(0,0%, 15%) */
    --muted-foreground: 240 5% 64.9%; /* hsl(240,5%, 64.9%) */
    --accent: 12 6.5% 15.1%; /* hsl(12,6.5%, 15.1%) */
    --accent-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --destructive: 0 62.8% 30.6%; /* hsl(0,62.8%, 30.6%) */
    --destructive-foreground: 0 85.7% 97.3%; /* hsl(0,85.7%, 97.3%) */
    --border: 240 3.7% 15.9%; /* hsl(240,3.7%, 15.9%) */
    --input: 240 3.7% 15.9%; /* hsl(240,3.7%, 15.9%) */
    --ring: 346.8 77.2% 49.8%; /* hsl(346.8,77.2%, 49.8%) */
    --text: 223 96.45% 98.48%; /* hsl(223,96.45%, 98.48%) */
  }
  .theme-blue {
    --background: 223 96.45% 98.48%; /* hsl(223,96%,98%) */
    --foreground: 223 66.8% 92.4; /* hsl(223,96%,98%) */
    --header: 210 40% 94%; /* hsl(210,40%,95%) */
    --gradient-from: 201 90% 27.45%; /*#075985 sky-800*/
    --gradient-to: 192 82% 31.45%; /*#0e7490 cyan-700*/
    --gradient-text: 0 0% 98%;
    --muted: 223 2.9% 92.4%; /* hsl(223,3%, 92%) */
    --muted-foreground: 223 1.45% 42.4%; /* hsl(223,1%, 42%) */
    --popover: 223 66.8% 92.4%; /* hsl(223,67%, 92%) */
    --popover-foreground: 223 66.8% 92.4%; /* hsl(223,66.8%,92.4%) */
    --card: 0 0% 100%; /* hsl(0,0%, 100%) */
    --card-foreground: 223 66.8% 92.4; /* hsl(240,10%, 4%) */
    --border: 223 1.45% 42.4%; /* hsl(223,1%, 42%) */
    --input: 223 5% 92%; /* hsl(223,5%, 92%) */
    --primary: 200 42% 49%; /* hsl(200,42%, 49%) */
    --primary-foreground: 223 0.58% 92.4%; /* hsl(223,1%, 92%) */
    --secondary: 223 1.45% 96.2%; /* hsl(223,1%, 96%) */
    --secondary-foreground: 223 2.7399999999999998% 12.4%; /* hsl(223,3%, 12%) */
    --accent: 223 1.45% 96.2%; /* hsl(223,1%, 96%) */
    --accent-foreground: 223 2.7399999999999998% 12.4%; /* hsl(223,3%, 12%) */
    --destructive: 0 84.2% 60.2%; /* hsl(0,84%, 60%) */
    --destructive-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
    --ring: 223 29% 24%; /* hsl(223,29%, 24%) */
    --text: 240 10% 4%; /* hsl(240,10%, 4%) */
  }
  .theme-blue.dark {
    --background: 222.2 84% 4.9%; /* hsl(222,84%, 5%) */
    --foreground: 210 40% 98%; /* hsl(210,40%, 98%) */
    --header: 222.2 84% 4.9%; /* hsl(222,84%, 5%) */
    --gradient-from: 204 80% 16%; /*#082f49 sky-950*/
    --gradient-to: 201 85% 22.45%; /* hsl(217.2,32.6%, 17.5%)*/
    --gradient-text: 0 0% 98%;
    --card: 221, 51%, 12%; /* hsl(221, 51%, 12%) */
    --card-foreground: 240 10% 3.9%; /* hsl(240,10%, 4%) */
    --popover: 222.2 84% 4.9%; /* hsl(222,84%, 5%) */
    --popover-foreground: 210 40% 98%; /* hsl(210,40%, 98%) */
    --primary: 200 42% 49%; /* hsl(200,42%, 49%) */
    --primary-foreground: 223 0.58% 92.4%; /* hsl(223,0.58%, 92.4%) */
    --secondary: 217.2 32.6% 17.5%; /* hsl(217.2,32.6%, 17.5%) */
    --secondary-foreground: 210 40% 98%; /* hsl(210,40%, 98%) */
    --muted: 217.2 32.6% 17.5%; /* hsl(217.2,32.6%, 17.5%) */
    --muted-foreground: 215 20.2% 65.1%; /* hsl(215,20.2%, 65.1%) */
    --accent: 217.2 32.6% 17.5%; /* hsl(217.2,32.6%, 17.5%) */
    --accent-foreground: 210 40% 98%; /* hsl(210,40%, 98%) */
    --destructive: 0 62.8% 30.6%; /* hsl(0,62.8%, 30.6%) */
    --destructive-foreground: 210 40% 98%; /* hsl(210,40%, 98%) */
    --border: 217.2 32.6% 17.5%; /* hsl(217.2,32.6%, 17.5%) */
    --input: 215 20.2% 65.1%; /* hsl(217.2,32.6%, 17.5%) */
    --ring: 224.3 76.3% 48%; /* hsl(224.3,76.3%, 48%) */
    --text: 223 96.45% 98.48%; /* hsl(223,96.45%, 98.48%) */
  }
  .theme-yellow {
    --background: 0 0% 100%; /* hsl(0,0%, 100%) */
    --foreground: 20 14.3% 4.1%; /* hsl(20,14.3%, 4.1%) */
    --header: 223 96.45% 98.48%; /* hsl(223,96%,98%) */
    --gradient-from: 45.4, 93.39%, 47.45%; /*#eab308 yellow-500*/
    --gradient-to: 47.95, 95.82%, 53.14%; /*##facc15 yellow-400*/
    --gradient-text: 0 0% 98%;
    --card: 0 0% 100%; /* hsl(0,0%, 100%) */
    --card-foreground: 20 14.3% 4.1%; /* hsl(20,14.3%, 4.1%) */
    --popover: 0 0% 100%; /* hsl(0,0%, 100%) */
    --popover-foreground: 20 14.3% 4.1%; /* hsl(20,14.3%, 4.1%) */
    --primary: 47.9 95.8% 53.1%; /* hsl(47.9,95.8%, 53.1%) */ /*hsl(47.9,95.8%, 53.1%)*/
    --primary-foreground: 26 83.3% 14.1%; /* hsl(26,83.3%, 14.1%) */
    --secondary: 60 4.8% 95.9%; /* hsl(60,4.8%, 95.9%) */
    --secondary-foreground: 24 9.8% 10%; /* hsl(24,9.8%, 10%) */
    --muted: 60 4.8% 95.9%; /* hsl(60,4.8%, 95.9%) */
    --muted-foreground: 25 5.3% 44.7%; /* hsl(25,5.3%, 44.7%) */
    --accent: 60 4.8% 95.9%; /* hsl(60,4.8%, 95.9%) */
    --accent-foreground: 24 9.8% 10%; /* hsl(24,9.8%, 10%) */
    --destructive: 0 84.2% 60.2%; /* hsl(0,84.2%, 60.2%) */
    --destructive-foreground: 60 9.1% 97.8%; /* hsl(60,9.1%, 97.8%) */
    --border: 20 5.9% 90%; /* hsl(20,5.9%, 90%) */
    --input: 20 5.9% 90%; /* hsl(20,5.9%, 90%) */
    --ring: 20 14.3% 4.1%; /* hsl(20,14.3%, 4.1%) */
    --text: 0 0% 25%; /* hsl(0,0%, 25%) */
  }
  .theme-yellow.dark {
    --background: 20 14.3% 4.1%; /* hsl(20,14.3%, 4.1%) */
    --foreground: 60 9.1% 97.8%; /* hsl(60,9.1%, 97.8%) */
    --header: 222.2 84% 4.9%; /* hsl(222,84%, 5%) */
    --gradient-from: 28.42, 72.52%, 25.69%; /*##713f12 yellow-800*/
    --gradient-to: 31.7, 80.95%, 28.82%; /*##854d0e yellow-700*/
    --gradient-text: 0 0% 98%;
    --card: 20 14.3% 4.1%; /* hsl(20,14.3%, 4.1%) */
    --card-foreground: 60 9.1% 97.8%; /* hsl(60,9.1%, 97.8%) */
    --popover: 20 14.3% 4.1%; /* hsl(20,14.3%, 4.1%) */
    --popover-foreground: 60 9.1% 97.8%; /* hsl(60,9.1%, 97.8%) */
    --primary: 47.9 95.8% 53.1%; /* hsl(47.9,95.8%, 53.1%) */
    --primary-foreground: 26 83.3% 14.1%; /* hsl(26,83.3%, 14.1%) */
    --secondary: 12 6.5% 15.1%; /* hsl(12,6.5%, 15.1%) */
    --secondary-foreground: 60 9.1% 97.8%; /* hsl(60,9.1%, 97.8%) */
    --muted: 12 6.5% 15.1%; /* hsl(12,6.5%, 15.1%) */
    --muted-foreground: 24 5.4% 63.9%; /* hsl(24,5.4%, 63.9%) */
    --accent: 12 6.5% 15.1%; /* hsl(12,6.5%, 15.1%) */
    --accent-foreground: 60 9.1% 97.8%; /* hsl(60,9.1%, 97.8%) */
    --destructive: 0 62.8% 30.6%; /* hsl(0,62.8%, 30.6%) */
    --destructive-foreground: 60 9.1% 97.8%; /* hsl(60,9.1%, 97.8%) */
    --border: 12 6.5% 15.1%; /* hsl(12,6.5%, 15.1%) */
    --input: 12 6.5% 15.1%; /* hsl(12,6.5%, 15.1%) */
    --ring: 35.5 91.7; /* hsl(35.5,91.7,) */
    --text: 223 96.45% 98.48%; /* hsl(223,96.45%, 98.48%) */
  }
  .radius {
    --radius: 0.8rem; /* hsl(0.8rem) */
  }
}
.theme-pink {
  --background: 0 0% 100%; /* hsl(0,0%, 100%) */
  --foreground: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
  --header: 223 96.45% 98.48%; /* hsl(223,96%,98%) */
  --gradient-from: 0, 90.6%, 70.78%; /* hsl(0,90.6%,70.78%) */ /*##f87171  red-900*/
  --gradient-to: 0, 93.55%, 81.76%; /* hsl(0,93.55%,81.76%) */ /*##fca5a5  red-800*/
  --gradient-text: 0 0% 98%;
  --card: 0 0% 100%; /* hsl(0,0%, 100%) */
  --card-foreground: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
  --popover: 0 0% 100%; /* hsl(0,0%, 100%) */
  --popover-foreground: 240 10% 3.9%; /* hsl(240,10%, 3.9%) */
  --primary: -31 92% 66%; /* hsl(0,92%, 66%) */
  --primary-foreground: -31 9.200000000000001% 6.6000000000000005%; /* hsl(-31,9.200000000000001%, 6.6000000000000005%) */
  --secondary: -31 46% 19.8%; /* hsl(-31,46%, 19.8%) */
  --secondary-foreground: -31 9.200000000000001% 98.3%; /* hsl(-31,9.200000000000001%, 98.3%) */
  --muted: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
  --muted-foreground: 240 3.8% 46.1%; /* hsl(240,3.8%, 46.1%) */
  --accent: 240 4.8% 95.9%; /* hsl(240,4.8%, 95.9%) */
  --accent-foreground: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
  --destructive: 0 84.2% 60.2%; /* hsl(0,84.2%, 60.2%) */
  --destructive-foreground: 0 0% 98%; /* hsl(0,0%, 98%) */
  --border: 240 5.9% 90%; /* hsl(240,5.9%, 90%) */
  --input: 240 5.9% 90%; /* hsl(240,5.9%, 90%) */
  --ring: 240 5.9% 10%; /* hsl(240,5.9%, 10%) */
  --text: 0 0% 25%; /* hsl(0,0%, 25%) */
}

.theme-pink.dark {
  --background: -31 59.800000000000004% 5.28%; /* hsl(-31,59.800000000000004%, 5.28%) */
  --foreground: -31 9.200000000000001% 98.3%; /* hsl(-31,9.200000000000001%, 98.3%) */
  --header: 222.2 84% 4.9%; /* hsl(222,84%, 5%) */
  --gradient-from: 0 74.7% 15.5%; /*#450a0a  red-950*/
  --gradient-to: 0 62.8% 30.7%; /*#7f1d1d  red-900*/
  --gradient-text: 0 0% 98%;
  --muted: -31 46% 19.8%; /* hsl(-31,46%, 19.8%) */
  --muted-foreground: -31 9.200000000000001% 56.6%; /* hsl(-31,9.200000000000001%, 56.6%) */

  --popover: -31 46.599999999999994% 8.58%; /* hsl(-31,46.599999999999994%, 8.58%) */
  --popover-foreground: -31 9.200000000000001% 98.3%; /* hsl(-31,9.200000000000001%, 98.3%) */

  --card: -31 46.599999999999994% 8.58%; /* hsl(-31,46.599999999999994%, 8.58%) */
  --card-foreground: -31 9.200000000000001% 98.3%; /* hsl(-31,9.200000000000001%, 98.3%) */

  --border: -31 46% 19.8%; /* hsl(-31,46%, 19.8%) */
  --input: -31 46% 19.8%; /* hsl(-31,46%, 19.8%) */

  --primary: -31 92% 66%; /* hsl(-31,92%, 66%) */
  --primary-foreground: -31 9.200000000000001% 6.6000000000000005%; /* hsl(-31,9.200000000000001%, 6.6000000000000005%) */

  --secondary: -31 46% 19.8%; /* hsl(-31,46%, 19.8%) */
  --secondary-foreground: -31 9.200000000000001% 98.3%; /* hsl(-31,9.200000000000001%, 98.3%) */

  --accent: -31 46% 19.8%; /* hsl(-31,46%, 19.8%) */
  --accent-foreground: -31 9.200000000000001% 98.3%; /* hsl(-31,9.200000000000001%, 98.3%) */

  --destructive: 0 62.8% 30.6%; /* hsl(0,62.8%, 30.6%) */
  --destructive-foreground: -31 9.200000000000001% 98.3%; /* hsl(-31,9.200000000000001%, 98.3%) */

  --ring: -31 92% 66%; /* hsl(-31,92%, 66%) */
  --text: 223 96.45% 98.48%; /* hsl(223,96.45%, 98.48%) */
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
